import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { Helmet } from 'react-helmet';
import {
  Menu,
  Container,
  Divider,
  Image, Grid, Header,
} from 'semantic-ui-react';

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import 'semantic-ui-css/semantic.min.css';
import './layout.css';

deckDeckGoHighlightElement().then();

function HeaderBar() {
  return (
    <Menu pointing fixed="top">
      <Container>
        <Menu.Item header name="Jonathan Altman" />
        <Link to="/"><Menu.Item name="Home" link /></Link>
        <Link to="/blog"><Menu.Item name="Blog" link /></Link>
        <Link to="/#about"><Menu.Item name="About" link /></Link>
        <Link to="/#contact"><Menu.Item name="Contact" link /></Link>
      </Container>
    </Menu>
  );
}

const Layout = ({ children }) => (
  <Container>
    <Helmet
      title="Homepage of Jonathan Altman"
      meta={[
        { name: 'description', content: 'Personal website of Jonathan Altman' },
        { name: 'keywords', content: 'homepage, website, Jonathan, Altman, Jonathan Altman' },
      ]}
    />
    <HeaderBar />
    <Container fluid style={{ marginTop: '4em' }}>
      <Grid padded>
        <Grid.Row id="home">
          <Container fluid className="inverted vertical masthead center aligned segment">

            <Container text textAlign="left">
              <Header as="h1" className="inverted">
                Jonathan Altman
              </Header>
              <Header as="h2" className="inverted">Dotcom Thousandaire</Header>
            </Container>

          </Container>
        </Grid.Row>
        <Grid.Row>
          {children}
        </Grid.Row>
      </Grid>
    </Container>
    <Container className="vertical footer segment">
      <Container fluid>
        <Divider />
        <p>
          &copy; (c) Jonathan Altman 2020.
          <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/3.0/">
            <Image alt="Creative Commons License" inline src="https://i.creativecommons.org/l/by-nc-nd/3.0/88x31.png" />
          </a>
          <br />
          <span>This work is licensed under a </span>
          <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/3.0/">
            Creative Commons Attribution-NonCommercial-NoDerivs 3.0 Unported License
          </a>
          <span>.</span>
        </p>
      </Container>
    </Container>
  </Container>
);

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
